<template>
    <v-container>
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid color="blue lighten-5">
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd" style="font-weight:600">Label<strong style="color:green;">*</strong><span></span></h6>
                                <v-autocomplete 
                                    dense
                                    solo class="ma-0 border-12" hide-details=true 
                                    v-model="label"
                                    :items="labels" 
                                    default="" 
                                    item-value="label" 
                                    item-text="label" 
                                    label="Label by"
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col xs="6" sm="6" md="2" class="col-4">
                                    <h6 class="blue-lcd" style="font-weight:600">Region<strong style="color:green;">*</strong><span></span></h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="region"
                                        :items="regions"
                                        label="Region"
                                        item-value="region" 
                                        item-text="region"
                                        class="border-12"
                                        hide-details=true
                                        @change="(event) => getOfficeID(event)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-4">
                                    <h6 class="blue-lcd" style="font-weight:600">Office<strong style="color:green;">*</strong><span></span></h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="office"
                                        :items="offices"
                                        item-value="office_id" 
                                        item-text="office"
                                        class="border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" v-if="label === 'DATE'">
                                    <h6 class="blue-lcd mb-2" style="font-weight:600">Start Date <strong style="color:red;">*</strong><span></span></h6>
                                    <v-menu
                                        ref="modal_request"
                                        v-model="modal_from"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="date_from"
                                            label="Start Date"
                                            persistent-hint
                                            append-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date_from"
                                            no-title
                                            @input="modal_from = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" v-if="label === 'DATE'">
                                    <h6 class="blue-lcd" style="font-weight:600">End Date <strong style="color:red;">*</strong><span></span></h6>
                                    <v-menu 
                                    ref="modal_to" 
                                    v-model="modal_to" :close-on-content-click="false"
                                    transition="scale-transition" 
                                    offset-y max-width="320px" 
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field solo 
                                            dense
                                            clearable
                                            v-model="date_to" 
                                            label="End Date" 
                                            persistent-hint
                                            append-icon="mdi-calendar" 
                                            v-bind="attrs" 
                                            v-on="on" 
                                            class="ma-0 pa-0 mb-1 border-12"
                                            hide-details=true></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                </v-menu>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2" v-if="label === 'DATE'">
                                <v-btn class="mt-7 border-12" 
                                style="padding: 19px;" 
                                small color="primary" 
                                elevation="2" 
                                @click="getPullData()">Search</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2"  v-if="label === 'MONTH'">
                                <h6 class="blue-lcd mb-2" style="font-weight:600">By Period <strong style="color:red;">*</strong><span></span></h6>
                                <v-menu ref="modal" 
                                v-model="modal" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period" 
                                        label="Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period" 
                                        type="month" 
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2" v-if="label === 'MONTH'">
                                <h6 class="blue-lcd mb-2" style="font-weight:600">To Period <strong style="color:red;">*</strong><span></span></h6>
                                <v-menu ref="modal_to_period" 
                                v-model="modal_to_period" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period_to" 
                                        label="To Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period_to" 
                                        type="month" 
                                        no-title @input="modal_to_period = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2" v-if="label === 'MONTH'">
                                <v-btn class="mt-7 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="getPullData()">Search</v-btn>
                            </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2" v-if="label === 'YEAR'">
                                    <h6 class="blue-lcd mb-2" style="font-weight:600">By Year <strong style="color:red;">*</strong><span></span></h6>
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="320px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                v-model="year"
                                                label="Year"
                                                append-icon="event"
                                                v-bind="attrs"
                                                v-on="on"
                                                solo
                                                class="ma-0 border-12"
                                                readonly
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            ref="picker"
                                            v-model="date"
                                            :max="picker"
                                            @input="save"
                                            reactive
                                            no-title>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2"  v-if="label === 'YEAR'">
                                    <h6 class="blue-lcd mb-2" style="font-weight:600">To Year <strong style="color:red;">*</strong><span></span></h6>
                                    <v-menu
                                        ref="menu_to_year"
                                        v-model="menu_to_year"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="320px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            v-model="year_to"
                                            label="To Year"
                                            append-icon="event"
                                            v-bind="attrs"
                                            v-on="on"
                                            solo
                                            class="ma-0 border-12"
                                            readonly
                                            hide-details=true
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="picker_to"
                                        v-model="date_to_year"
                                        :max="picker"
                                        @input="save_date_to_year"
                                        reactive
                                        no-title>
                                    </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2"  v-if="label === 'YEAR'">
                                    <v-btn class="mt-7 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="getPullData()">Search</v-btn>
                                </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-card  class="pa-md-5">
                                <h5 class="orange-text text-darken-3 m-0" style=" font-weight: 900;">Depo Occupancy</h5>
                                <h6 class="cyan-text text-darken-3 m-0">KMB</h6>
                                <div id="chartContainer1" style="height: 370px; width: 100%;"></div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>

                <!-- <v-col cols="12">
                <v-card>
                    <h6 class="cyan-text text-darken-3 m-0 mt-2 ml-4">Depo Occupancy</h6> 
                    <div id="chartContainer1" class="hahe" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {master} from "../../../backend-api/kmb/master"
export default {
    data () {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search:'',
            breadcumbs: [
                {
                    text: 'KMB',
                    disabled: false,
                    href: '/admin/kmb',
                },
                {
                    text: 'Occupancy',
                    disabled: true,
                },
                {
                    text: 'Depo Occupancy',
                    disabled: true,
                }
            ],
            chartContainer1:[],
            regions:[],
            offices:[],
            label: 'DATE',
            labels: [
                {label:'DATE'},
                {label:'MONTH'},
                {label:'YEAR'},
            ], 
            modal_from:false,
            picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal_to:false,
            modal_to_period: false,
            menu_to_year: false,
            menu: false,
            modal: false,
            date:'',
            date_from:'',
            date_to:'',
            date_from:'',
            period: '',
            year: '',
            period_to: '',
            year_to: '',
            date_to_year: '',
            region:'',
            office:'',
        }
        
    },
    mounted(){
        this.getRegion()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {

    save_date_to_year(date) {
            console.log(date);

            this.$refs.menu_to_year.save(date)

            this.$refs.picker_to.activePicker = 'YEAR'

            this.menu_to_year = false

            this.year_to = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
        },
    save(date) {
            console.log(date);

            this.$refs.menu.save(date)

            this.$refs.picker.activePicker = 'YEAR'

            this.menu = false

            this.year = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
    },
    getFilter(){},
    async getRegion(event){
        const respData = await master.fetchRegion('', null, false, false, false)
        if (respData.status === 200) {
            this.regions = respData.data.data
        }
    },   
    async getOfficeID(event){
            if (event === '' || event === null) {
                this.office = ''
            } else {
                // var reqBody = {
                //     'regionid': this.region
                // }
                const respData = await master.fetchOffice(`?region=${this.region}`, null, false, false, false)
                if (respData.status === 200) {
                    this.offices = respData.data.data
                }
            }
        }, 
    async getPullData() {
        var periodStart = ''
        var periodEnd = ''
        if (this.label === 'DATE') {
                periodStart = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10) : ''
                periodEnd = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10) : ''
            } else if(this.label === 'MONTH'){
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''
            } else if(this.label === 'YEAR'){
                periodStart = this.year ? new Date(this.year).toISOString().substr(0, 4).replace(/-/g,"") : ''
                periodEnd = this.year_to ? new Date(this.year_to).toISOString().substr(0, 4).replace(/-/g,"") : ''
            }  
        this.$store.dispatch('setOverlay', true)
        if (this.date_from == '' || this.date_from === null) {
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Opps.. !",
                        text: 'Please Insert Complate Start Date & End Date !',
                        visible: true
                    };
                    this.$store.dispatch('setOverlay', false)       
            }else{
            await axios.get(`${process.env.VUE_APP_URL}/v2/kmb/occupancy/GetData?period_start=${periodStart ? periodStart : ""}&period_end=${periodEnd ? periodEnd : ""}&label=${this.label ? this.label : ""}&region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, {
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
            })
            .then(res => {
            this.$store.dispatch('setOverlay', false) 
            var dataPoints1 = res.data.map(item => {
            console.log(res.data);
            var wgtEnd = parseFloat(item.wgt_end);
            return { label: item.closing_date, y: wgtEnd };
            });

            var dataPoints2 = res.data.map(item => {
                var kapasitas = parseFloat(item.kapasitas);
                return { label: item.closing_date, y: kapasitas };
            });
                    
                this.renderChart1(dataPoints1, dataPoints2);
                this.$store.dispatch('setOverlay', false)
            })
            
            }
            
        },
        renderChart1(datapoints1, datapoints2)
        {
            console.log(datapoints1);
            console.log(datapoints2);
           //  console.log(datapoints3);
           this.$store.dispatch('setOverlay', false)
            var chart1 = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                legend: {
                    fontColor: "red",
                    cursor: "pointer",
                    // itemclick: this.toggleDataSeries
                },
                axisY: [
                    {
                        crosshair: {
                            enabled: true,
                            snapToDataPoint: true
                        },
                        title: "TON",
                        labelFormatter: this.addSymbols,
                    },
                ],
                exportEnabled: true,
                toolTip:{
                    shared:true
                },
                data: [{
                    type: "line",
                    indexLabel: "{y}",
                    name: 'Wgt',
                    indexLabelFontSize: 12,
                    showInLegend: true,
                    color: "#eb3734",
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    yValueFormatString: "###,###",
                    indexLabelFontColor: "white"
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    name: 'Kapasitas',
                    indexLabelFontSize: 12,
                    color:"#2fc92c",
                    showInLegend: true,
                    indexLabelPlacement: "inside",
                    indexLabelOrientation: "vertical",
                    yValueFormatString: "###,###",
                    indexLabelFontColor: "white"
                }]
            });
            chart1.options.data[0].dataPoints = datapoints1;
            chart1.options.data[1].dataPoints = datapoints2;
            // chart1.options.data[2].dataPoints = datapoints3;
            var x =  window.matchMedia("(max-width: 991px)");
            if (x.matches) {
                for(var i = 0; i < chart1.options.data.length; i++){
                    chart1.options.data[i].indexLabelFontSize = 6;
                }
                chart1.render();
            }

            chart1.render();

        },
    },
    watch: {
        menu (val) {
            console.log(val);
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        menu_to_year (val) {
            console.log(val);
            val && setTimeout(() => (this.$refs.picker_to.activePicker = 'YEAR'))
        },
    },

}
</script>

<style>

</style>