import {backendApi} from "../backend-api-sr"

export const master = (() =>{
    const fetchMill = ( async (subPath) => {
        var url = '/api/master/kmb/mill'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchOffice = ( async (subPath) => {
        var url = '/api/master/kmb/branch_office/office'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchRegion = ( async (subPath) => {
        var url = '/api/master/kmb/branch_office/region'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchCategory = ( async (subPath) => {
        var url = '/api/master/kmb/barang_jadi'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchFinishGoods = ( async (subPath) => {
        var url = '/api/master/kmb/barang_jadi/barangjadi'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchSales = ( async (subPath) => {
        var url = '/api/master/kmb/sales'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchCategoryFastMove = ( async (subPath) => {
        var url = '/api/master/kmb/barang_jadi/category_fast_move'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchFinishGoodsFastMove = ( async (subPath) => {
        var url = '/api/master/kmb/barang_jadi/barangjadi_fast_move'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    return {fetchMill, fetchOffice, fetchRegion, fetchCategory, fetchFinishGoods, fetchSales, fetchCategoryFastMove, fetchFinishGoodsFastMove};
})()